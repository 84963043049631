
import { defineComponent } from 'vue'

// Utils
import utilities from "../utilities";

import Button from "@/components/Button.vue"

export default defineComponent({
    name: "OrderDetail",
    components:{
        Button
    },
    data(){
        return{
            loading: false,
            token: '',
            status: {
                pending: {
                    translation: 'Pendiente de Pago',
                    color: '#607d8b'
                },
                paid: {
                    translation: 'Pago',
                    color: '#4caf50'
                },
                printing: {
                    translation: 'Imprimiendo',
                    color: '#ff9800'
                },
                'on-way': {
                    translation: 'En Camino',
                    color: '#673ab7'
                },
                unknown: {
                    translation: 'Desconocido',
                    color: '#f44336'
                },
                delivered: {
                    translation: 'Entregado',
                    color: '#2196f3'
                }
            },
            config: [],
            order: [] as any[any],
            paymentLink: null
        }
    },
    mounted(){
        this.loading = true
        //check auth and get token
        let checkAuth = utilities.checkAuth('mi-cuenta') as any
        Promise.resolve(checkAuth).then((data) => {
            this.token = data.token as any

            //get config
            let config = utilities.getItems('config/1?fields=*', this.token) as any
            Promise.resolve(config).then((data: any[any]) => {   
                this.config = data
            })

            //get order
            let orders= utilities.getItems('orders?fields=*,shipping_method.*,items.*&filter[id][eq]='+this.$route.params.id+'&sort=-created_on', this.token) as any
            Promise.resolve(orders).then((data: any[any]) => {
                this.order = data[0]

                //get payment link for card
                if(this.order.status == 'pending' && this.order.payment_method == 'card'){
                    this.getPaymentLink()
                }
                if(this.order.status == 'pending' && this.order.payment_method == 'mp'){
                    this.getPaymentMP()
                }

                this.loading = false
            })
        })
    },
    methods:{
        async getPaymentLink(){
            let paymentData = await utilities.createPaymentHash(this.order) as any
            this.paymentLink = paymentData.url
        },
        async getPaymentMP(){
            let paymentData = await utilities.paymentMP(
                this.order.shipping_first_name + " " + this.order.shipping_last_name, //nombre
                1, //quantity
                this.order.total, //total price
                this.order.shipping_track_id,
                this.order.id) as any 
            this.paymentLink = paymentData.result.init_point
        }
    }
})
